<template>
    <b-form-input :type="field.inputtype ? field.inputtype : 'text'" :autofocus="field.autofocus"
                  :class="field.class" :style="field.style" :disabled="state.disabled"
                  :placeholder="field.placeholder" size="sm"
                  v-model="data[field.name]"
                  v-on:input="$emit('input')"/>
</template>

<script>
export default {
    name: "TextField",
    props: ['field', 'data', 'state'],
}
</script>

<style scoped>
</style>