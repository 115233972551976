<template>
    <router-link :to="action.to" :class="action.class" :style="action.style">{{ action.text }}</router-link>
</template>

<script>
export default {
    name: "LinkAction",
    props: ["action"],
}
</script>

<style scoped>
</style>
