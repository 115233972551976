<template>
    <b-modal id="record-dialog" :size="size"
             :no-close-on-esc="type !== 'detail'"
             no-close-on-backdrop
             v-on:ok="onOk"
             v-on:cancel="onCancel"
             v-on:hidden="onHidden"
             v-on:shown="onShown">

        <template #modal-title>
            <font-awesome-icon :icon="dialogIcon" class="mr-2"/>
            {{ dialogTitle }}
        </template>

        <div style="font-size: .875rem;">
            <RecordForm :key="'recordform-'+this.recordFormReload" v-bind="recordFormParameters"
                        v-on:create-relation="newRecordForm('create', $event)"
                        v-on:update-relation="newRecordForm('update', $event)"
                        v-on:input="onInput"
                        v-on:model-data="onModelData"/>
        </div>

        <template #modal-footer="{ ok, cancel }">
            <b-button pill :variant="dialogOkVariant" class="pl-5 pr-5" :disabled="dialogOkDisabled || dialogButtonsDisabled"
                      v-on:click="ok()">
                {{ dialogOkLabel }}
            </b-button>
            <b-button pill variant="dark" class="pl-5 pr-5 ml-2" :disabled="dialogButtonsDisabled"
                      v-if="type !== 'detail'"
                      v-on:click="cancel()">
                {{ $i18n.t("translations.Cancel") }}
            </b-button>
        </template>

    </b-modal>
</template>

<script>
import RecordForm from "@/components/forms/RecordForm";

export default {
    name: "RecordDialog",
    components: {
        RecordForm,
    },
    props: {
        type: {type: String, default: null},
        size: {type: String, default: 'xl'},
        title: {type: String, default: null},
        fields: {type: Array, default: null},
        data: {type: Object, default: null},
        api: {type: String, default: null},
        ok_label: {type: String, default: null},
        ok_event: {type: String, default: 'ok'},
        userLanguage: {type: String, default: 'en'},
        languages: {type: Array, default: null},
    },
    data() {
        return {
            dialogType: null,
            dialogTitle: null,
            dialogField: null,
            dialogData: null,
            dialogApi: null,
            dialogButtonsDisabled: false,
            stack: [],
            recordFormReload: null,
            recordFormParameters: {
                type: null,
                fields: null,
                data: null,
                readonly: false,
                formErrors: null,
                generalError: null,
                userLanguage: this.userLanguage,
                languages: this.languages,
            },
            inputData: null,
            modelData: null,
        }
    },
    computed: {
        dialogIcon: function () {
            if (this.dialogType === 'create') {
                return ['fas', 'plus-circle'];
            } else if (this.dialogType === 'delete') {
                return ['fas', 'trash-alt'];
            } else if (this.dialogType === 'detail') {
                return ['fas', 'list-ul'];
            } else {
                return ['fas', 'pen'];
            }
        },
        dialogOkDisabled: function () {
            return !(this.recordFormParameters.readonly || this.modelData);
        },
        dialogOkLabel: function () {
            if (this.ok_label) {
                return this.ok_label;
            } else if (this.dialogType === 'create') {
                return this.$i18n.t('translations.Create');
            } else if (this.dialogType === 'delete') {
                return this.$i18n.t('translations.Delete');
            } else if (this.dialogType === 'detail') {
                return this.$i18n.t('translations.Close');
            } else {
                return this.$i18n.t('translations.Edit');
            }
        },
        dialogOkVariant: function () {
            if (this.dialogType === 'create') {
                return 'success';
            } else if (this.dialogType === 'delete') {
                return 'danger';
            } else if (this.dialogType === 'detail') {
                return 'dark';
            } else {
                return 'warning';
            }
        },
    },
    mounted() {
        this.initRecordForm(null, this.type, this.title, this.fields, this.data, this.api);
        this.$bvModal.show('record-dialog');
    },
    methods: {
        newRecordForm(type, field) {
            let element = {
                field: this.dialogField,
                type: this.dialogType,
                title: this.dialogTitle,
                fields: this.recordFormParameters.fields,
                data: {...this.dialogData, ...this.inputData},
                api: this.dialogApi,
            };
            this.stack.push(element);
            if (type === 'create') {
                this.initRecordForm(field, type, field.label, field['createrelation'], null, field.api);
            } else {
                this.initRecordForm(field, type, field.label, field['updaterelation'], element.data[field.name], field.api);
            }
        },
        createRecord() {
            this.recordFormParameters.formErrors = null;
            this.recordFormParameters.generalError = null;
            this.$http.post(this.dialogApi, this.modelData, {}).then((res) => {
                if (this.stack.length) {
                    this.previousRecordForm(res.data.data);
                } else {
                    this.$emit(this.ok_event, res.data);
                    this.$bvModal.hide('record-dialog');
                }
            }).catch((error) => {
                this.handleFormErrors(error);
            });
        },
        deleteRecord() {
            this.recordFormParameters.formErrors = null;
            this.recordFormParameters.generalError = null;
            let url = this.dialogApi;
            if ('id' in this.dialogData) {
                url += '/' + this.dialogData.id;
            }
            this.$http.delete(url).then((res) => {
                this.$emit(this.ok_event, res.data);
                this.$bvModal.hide('record-dialog');
            }).catch((error) => {
                this.handleFormErrors(error);
            });
        },
        handleFormErrors(error) {
            this.dialogButtonsDisabled = false;

            if (error.response.status === 422) {
                this.recordFormParameters.formErrors = error.response.data.errors;
            } else {
                this.recordFormParameters.generalError = error.response.data.message;
                console.log("RecordDialog:handleFormErrors():error:", error);
            }
        },
        initRecordForm(field, type, title, fields, data, api) {
            this.dialogButtonsDisabled = false;

            this.dialogField = field;
            this.dialogType = type;
            this.dialogTitle = title;
            this.dialogData = data ? data : {};
            this.dialogApi = api;

            this.inputData = null;
            this.modelData = null;

            this.recordFormParameters.readonly = (this.dialogType === 'delete' || this.dialogType === 'detail');
            this.recordFormParameters.type = type;
            this.recordFormParameters.fields = fields;
            this.recordFormParameters.data = data;
            this.recordFormParameters.formErrors = null;
            this.recordFormParameters.generalError = null;
            this.recordFormReload++;
        },
        onCancel(event) {
            if (this.stack.length) {
                event.preventDefault();
                this.previousRecordForm();
            }
        },
        onHidden() {
            this.$emit('hidden');
        },
        onInput(data) {
            this.inputData = data;
        },
        onModelData(data) {
            this.modelData = data;
        },
        onOk(event) {
            if (this.dialogType !== 'detail') {
                event.preventDefault();
                this.dialogButtonsDisabled = true;
                if (this.dialogType === 'create') {
                    this.createRecord();
                } else if (this.dialogType === 'delete') {
                    this.deleteRecord();
                } else if (this.dialogType === 'update') {
                    this.updateRecord();
                }
            }
        },
        onShown() {
            this.$nextTick(() => {
                var activeElement = document.activeElement;
                var inputs = ['input', 'textarea'];
                if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
                    document.activeElement.select();
                }
            });
        },
        previousRecordForm(data) {
            let element = this.stack.pop();
            if (data) {
                element.data[this.dialogField['name']] = data;
            }
            this.initRecordForm(element.field, element.type, element.title, element.fields, element.data, element.api);
        },
        updateRecord() {
            this.recordFormParameters.formErrors = null;
            this.recordFormParameters.generalError = null;
            let url = this.dialogApi;
            if ('id' in this.dialogData) {
                url += '/' + this.dialogData.id;
            }
            this.$http.put(url, this.modelData, {}).then((res) => {
                if (this.stack.length) {
                    this.previousRecordForm(res.data.data);
                } else {
                    this.$emit(this.ok_event, res.data);
                    this.$bvModal.hide('record-dialog');
                }
            }).catch((error) => {
                this.handleFormErrors(error);
            });
        },
    }
}
</script>

<style scoped>
</style>
