<template>
    <button type="button" :class="actionClass" :style="this.action.style" :disabled="disabled"
            v-on:click="$emit('toggle-action')">
        <font-awesome-icon :icon="action.on.icon" size="sm" :class="{'mr-2':!action.compact}" v-if="!isOn"/>
        <font-awesome-icon :icon="action.off.icon" size="sm" :class="{'mr-2':!action.compact}" v-if="isOn"/>
        <template v-if="!action.compact">{{ isOn ? action.on.label : action.off.label }}</template>
    </button>
</template>

<script>
export default {
    name: "ToggleAction",
    props: ["action", "disabled", "isOn"],
    computed: {
        actionClass: function () {
            if (this.isOn) {
                return 'btn btn-sm ' + (this.action.on.class ? this.action.on.class : this.action.class);
            } else {
                return 'btn btn-sm ' + (this.action.off.class ? this.action.off.class : this.action.class);
            }
        }
    },
}
</script>

<style scoped>
</style>
