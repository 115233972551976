<template>
    <b-form-checkbox name="checkbox" :class="field.class" :style="field.style" :disabled="state.disabled"
                     value=1 unchecked-value=0 :switch="field.switch" size="sm"
                     v-model="data[field.name]"
                     v-on:input="$emit('input')"/>
</template>

<script>

export default {
    name: "BoolField",
    props: ['field', 'data', 'state'],
}
</script>

<style scoped>
</style>